@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

:root {
  --default-orange: #fb4f14;
  --default-background: #141414;
  --secondary-background: #ebebeb;
  --error-color: #ff3030;
  --home-grid: 1104px;
}

/* Scrollbar for Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--default-orange) transparent;
}

/* Scrollbar for Chrome, Safari and Edge */
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: var(--default-orange);
  border-radius: 3px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-flex-negative: 1;
}
a {
  text-decoration: none;
}

html,
body,
#root {
  height: 100%;
  background-color: var(--default-background);
}

.flow {
  background-color: var(--default-background);
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@media screen and (max-width: 1439px) and (min-width: 1281px) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-width: 1280px) and (min-width: 1024px) {
  html {
    font-size: 12.5px;
  }
}
@media screen and (max-width: 1023px) and (min-width: 998px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 997px) {
  html {
    font-size: 16px;
  }
}

button,
a {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

button:hover,
a:hover {
  filter: brightness(0.9);
}

button:disabled {
  cursor: not-allowed;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--default-background) inset !important;
  -webkit-text-fill-color: #fff;
  color: #fff !important;
  font-family: 'Lato', sans-serif;
}

.Toastify__toast-container {
  padding: 0;
}

.Toastify__toast-container--top-center {
  width: 100%;
  left: 0;
  top: 0;
  transform: none;
}

.Toastify__toast {
  width: 100%;
  border-radius: 0;
}

.Toastify__toast--error {
  background: var(--error-color);
}

.Toastify__close-button {
  align-self: center;
}
